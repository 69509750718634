import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _44b764da = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _5355aed7 = () => interopDefault(import('../pages/activities.vue' /* webpackChunkName: "pages/activities" */))
const _32ba372c = () => interopDefault(import('../pages/assessment.vue' /* webpackChunkName: "pages/assessment" */))
const _71f86922 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5f70148e = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _50e7e30d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _5c7c41b0 = () => interopDefault(import('../pages/substances/index.vue' /* webpackChunkName: "pages/substances/index" */))
const _d1081556 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _dbc8e2fa = () => interopDefault(import('../pages/substances/_substance.vue' /* webpackChunkName: "pages/substances/_substance" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _44b764da,
    name: "about"
  }, {
    path: "/activities",
    component: _5355aed7,
    name: "activities"
  }, {
    path: "/assessment",
    component: _32ba372c,
    name: "assessment"
  }, {
    path: "/login",
    component: _71f86922,
    name: "login"
  }, {
    path: "/privacy",
    component: _5f70148e,
    name: "privacy"
  }, {
    path: "/register",
    component: _50e7e30d,
    name: "register"
  }, {
    path: "/substances",
    component: _5c7c41b0,
    name: "substances"
  }, {
    path: "/support",
    component: _d1081556,
    name: "support"
  }, {
    path: "/substances/:substance",
    component: _dbc8e2fa,
    name: "substances-substance"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
